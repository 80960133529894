@use 'colors' as c;

.summaryAbout,
.issueSummary,
.CTA {
  margin: 0 auto;
  padding: 2em 50px;
  max-width: 1920px;
}
.hero {
  padding: 2em 50px;
  max-width: 1920px;
  margin: 0 auto;

  .heroParagraph {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
  }
  .existingSub {
    font-weight: 600;
    font-size: 3vw;
    color: #000000d9;
  }
  & #{&}Image {
    width: 100%;
    align-self: stretch;
    justify-self: center;
    max-height: 375px;

    mask-image: radial-gradient(closest-side, #fff 60%, transparent 100%);
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: all 0.2s ease-in-out;
    }
  }
}
.hero {
  color: #000000d9;
  .heroCta_existingSub {
    padding: 0;
    margin: 0;
    font-size: 3.5em;
  }
  .heroCta_signOutLink {
    padding: 0;
    margin: 0 0 1em;
    text-decoration: underline;
    color: purple;
  }
  .heroCta__DonateButton,
  .heroCta__SignUpButton,
  .heroCta__SupportDonateButton {
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
    }
  }
  .heroCta__DonateButton {
    background-color: c.$primary;
    border-radius: 20px;
    border: 2px solid transparent;
    color: #fff;
    font-weight: 600;
  }
  .heroCta__SignUpButton {
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid c.$primary;
    font-weight: 600;
    color: c.$primary;
    &:hover {
      background-color: c.$primary;
      color: #fff;
    }
  }
  .heroCta__SupportDonateButton {
    background-color: c.$primary;
    border-radius: 20px;
    border: 2px solid transparent;
    font-weight: 600;
    color: #fff;
    margin: 0 auto;
  }
}

.summaryAbout {
  margin-top: 2em;
  padding-top: 2.5em;
  background-color: c.$primary;
  color: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  min-height: 300px;

  button {
    background-color: #fff;
    border-radius: 20px;
    border: none;
    color: c.$primary;
  }
  .aboutImg {
    position: absolute;
    align-self: flex-end;
    object-position: 0 -95px;
    width: 600px;
    height: 250px;
    object-fit: cover;
  }
}

.issueSummary {
  text-align: center;
}

.sumText {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  padding: 0 10%;
}

.CTA {
  text-align: center;
  color: #fff;

  h2,
  label {
    color: #fff;
  }
  .CTA_existingSub {
    padding: 0;
    margin: 0;
    font-size: 3.5em;
  }
  .CTA_signOutLink {
    text-decoration: underline;
  }
  .CTA__SignUpButton {
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid #fff;
    font-weight: 600;
    color: #fff;
  }
  .CTA__DonateButton {
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid transparent;
    color: c.$primary;
    font-weight: 600;
  }
  .CTA__SupportDonateButton {
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid transparent;
    color: c.$primary;
    font-weight: 600;
  }
}

.codeBehindCTA {
  background-color: c.$primary;
}

footer {
  .footerLeft {
    text-align: right;
  }
}
@media only screen and (max-width: 991px) {
  .hero {
    .heroImage {
      mask: none;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 765px) {
  .ant-layout-header {
    padding: 0 25px;
  }
  .logo {
    font-size: 1.5em;
  }
  .hero,
  .summaryAbout,
  .issueSummary,
  .CTA {
    padding: 2em 25px;
  }
  .hero {
    .existingSub {
      font-size: 2.5em;
      margin: 0.25em;
    }
    .heroImage {
      display: flex;
      align-items: center;
      justify-content: center;
      mask: none;
      img {
        height: 200px;
        object-fit: contain;
      }
    }
  }

  .summaryAbout {
    overflow: hidden;
    .aboutImg {
      position: relative;
      margin-bottom: -5em;
      object-position: 0 -55px;
    }
  }
}

@media only screen and (max-width: 255px) {
  .hero {
    .heroImage {
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
