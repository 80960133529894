@import "~antd/dist/antd.css";
@import "./homeStyle.scss";
@import "./donationStyle.scss";
@import "./issuesStyle.scss";
@import "./admin.scss";
* {
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}
.window {
  position: relative;
  min-height: 100vh;

  .logo {
    font-size: 1.5em;
    a {
      color: black;
    }
  }
  .mainContent {
    margin-top: 65px;
    margin-bottom: 150px;
    position: relative;
    .paymentBackground {
      position: absolute;
      z-index: 0;
      left: 0;
      height: 100%;
      img {
        height: 100%;
        mask-image: linear-gradient(to left, transparent 70%, #f1f2f5 90%);
      }
    }
  }
  footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1em;
    .footerContent {
      width: 80%;
    }
    .footerLeft {
      text-align: left;
    }
    .footerRight {
      text-align: right;
      a {
        position: relative;
        color: #000000d9;
      }
      a:before {
        position: absolute;
        content: "";
        border-bottom: 1px solid black;
        bottom: -3px;
        left: 50%;
        right: 50%;
        transition: all 0.2s ease-in-out;
      }
      a:hover:before {
        position: absolute;
        content: "";
        border-bottom: 1px solid black;
        bottom: -3px;
        left: 0%;
        right: 0%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .window {
    .logo {
      font-size: 1em;
      margin: 0;
      padding: 0;
    }
    .mainContent {
      margin-bottom: 280px;
      .paymentBackground {
        position: relative;
        height: 80px;
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          mask-image: none;
        }
      }
    }
    footer {
      .footerContent {
        width: 80%;
      }
      .footerLeft {
        text-align: center;
      }
      .footerRight {
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 300px) {
  .window {
    .logo {
      font-size: 0.8em;
    }
  }
}
