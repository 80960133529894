.articleMainHeader {
  text-align: center;
  font-size: 2em;
}

.issueArticle {
  padding: 0.75em 0.25em;
  text-indent: 1em;
  width: 80%;
  margin: 0 auto;
  font-family: serif;
  text-align: justify;
  font-size: 1.25em;
}
