.adminWindowContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: 225px;
}

.adminContainer {
  margin: 0 auto;
  padding: 1em;
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  header {
    width: 100%;
    height: 10%;
    font-size: 1.5em;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
  }
}

section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscriberTable {
  width: 100%;
}

.accountSettings {
  header {
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  main {
    .changePassword,
    .showUser {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #cecece;
      padding: 2em 0;
    }

    .showUser {
      align-items: center;
      h2,
      p {
        margin: 0;
        padding: 0;
      }
    }
    form {
      .inputGroup {
        margin: 1em 0;
      }
    }
  }
}
